const links = [
  // {
  //   title: 'Доставка',
  //   to: '#delivery',
  // },
  {
    title: 'Схема работы',
    to: '#work',
  },
  {
    title: 'Преимущества',
    to: '#benefits',
  },
  {
    title: 'Тарифы',
    to: '#tariffs',
  },
  {
    title: 'Документы',
    to: 'https://lk.belkapost.ru/terms.pdf',
    external: true,
  },
]

export { links };
