import {
  ADD_CALCULATION_RESULTS,
  AddCalculationResults, AdditionalServices,
  GeoSuggest,
  Operator,
  Package,
  RESET_CALCULATION,
  ResetCalculation, SET_ADDITIONAL_SERVICES,
  SET_CALCULATION_DONE,
  SET_CALCULATION_RESULTS,
  SET_CALCULATION_TASK_ID,
  SET_CENT,
  SET_OPERATORS,
  SET_ORDERING,
  SET_RECEIVER_ADDRESS,
  SET_SENDER_ADDRESS,
  SET_WEIGHT, SetAdditionalServices,
  SetCalculationDone,
  SetCalculationResults,
  SetCalculationTaskId,
  SetCent,
  SetOperators,
  SetOrdering,
  SetReceiverAddress,
  SetSenderAddress,
  SetWeight,
  SingleRate, SWITCH_CURRENT_FILTER, SwitchCurrentFilter,
  UPDATE_CARGO, UPDATE_CURRENT_DELIVERY_SERVICE_FILTER, UPDATE_SERVICES_FILTER,
  UpdateCargo, UpdateCurrentDeliveryServiceFilter, UpdateServicesFilter
} from './types';
import { ThunkAction } from '../../types';
import { adaptToApi } from '../../utils/adapter';

export const setOperators = (operators: Array<Operator>): SetOperators => ({
  type: SET_OPERATORS,
  operators,
});

export const setCent = (cent: any): SetCent => ({
  type: SET_CENT,
  cent,
});


export const setSenderAddress = (address: GeoSuggest): SetSenderAddress => ({
  type: SET_SENDER_ADDRESS,
  address,
});

export const setReceiverAddress = (address: GeoSuggest): SetReceiverAddress => ({
  type: SET_RECEIVER_ADDRESS,
  address,
});

export const resetCalculation = (): ResetCalculation => ({
  type: RESET_CALCULATION,
});

export const setCalculationTaskId = (taskId: string): SetCalculationTaskId => ({
  type: SET_CALCULATION_TASK_ID,
  taskId,
});

export const setCalculationResults = (results: SingleRate[]): SetCalculationResults => ({
  type: SET_CALCULATION_RESULTS,
  results,
});

export const addCalculationResults = (results: SingleRate[]): AddCalculationResults => ({
  type: ADD_CALCULATION_RESULTS,
  results,
});

export const setWeight = (weight: string): SetWeight => ({
  type: SET_WEIGHT,
  weight,
});

export const updateCargo = (cargo: Partial<Package>): UpdateCargo => ({
  type: UPDATE_CARGO,
  cargo,
});

export const setOrdering = (ordering: 'price' | 'minDays'): SetOrdering => ({
  type: SET_ORDERING,
  ordering,
});

export const setCalculationDone = (done: boolean): SetCalculationDone => ({
  type: SET_CALCULATION_DONE,
  done,
});

export const updateCurrentDeliveryServicesFilter = (deliveryServices: Array<string>): UpdateCurrentDeliveryServiceFilter => ({
  type: UPDATE_CURRENT_DELIVERY_SERVICE_FILTER,
  deliveryServices,
});

export const updateServicesFilter = (services: string[], initial: boolean = false): UpdateServicesFilter => ({
  type: UPDATE_SERVICES_FILTER,
  services,
  initial,
});

export const setAdditionalServices = (services: AdditionalServices): SetAdditionalServices => ({
  type: SET_ADDITIONAL_SERVICES,
  services,
});

export const switchCurrentFilters = (filter: 'deliveryServices'): SwitchCurrentFilter => ({
  type: SWITCH_CURRENT_FILTER,
  filter,
});



export const loadOperators = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/reference/operator/').then(
  (response: {deliveryServices: Array<Operator> }) => {
    dispatch(setOperators(response.deliveryServices));
    return Promise.resolve(response);
  },
);

export const geoSuggest = (query: string): ThunkAction<Promise<{ suggestions: Array<GeoSuggest> }>> => (dispatch, getState, http) => http.get(
  '/api/v1/geo/suggest/', { query },
);

export const createAddressCast = (address: GeoSuggest): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/contact/cast/address/fast/create/', adaptToApi(address),
);

export const createCargo = (): ThunkAction => (dispatch, getState, http) => {
  const { cargo } = getState().calculation;
  let weight = cargo.weight;

  if (weight) {
    try {
      weight = parseFloat(String(weight).replace(',', '.'))
    }
    catch (e) {
      weight = 0.5;
    }
  }

  return http.post('/api/v1/cargo/create/',
    { packages: [{ ...cargo, description: 'Документы', amount: 1, weight, width: 10, height: 10, length: 10 }] });
};

export const startCalculation = (): ThunkAction => async (dispatch, getState, http) => {
  const { senderAddress, receiverAddress, cargo } = getState().calculation;
  dispatch(resetCalculation());
  const errors: anyObject = {};
  if (!senderAddress) {
    errors['sender'] = 'Это поле обязательно к заполнению';
  }

  if (!receiverAddress) {
    errors['receiver'] = 'Это поле обязательно к заполнению';
  }

  if (!Object.keys(cargo).length) {
    errors['cargo'] = 'Это поле обязательно к заполнению';
  }

  if (Object.keys(errors).length) {
    return Promise.reject(errors);
  }

  if (senderAddress && receiverAddress) {
    const [{ addressCastId: senderAddressCast }, { addressCastId: receiverAddressCast }, { cargoId }] = await Promise.all(
      [
        dispatch(createAddressCast(senderAddress)),
        dispatch(createAddressCast(receiverAddress)),
        dispatch(createCargo()),
      ],
    );
    http.post('/api/v1/calculation/start/', {
      sender_address_id: senderAddressCast,
      receiver_address_id: receiverAddressCast,
      cargo_id: cargoId,
    }).then(
      ({ singleTaskId }: { singleTaskId: string }) => {
        dispatch(setCalculationTaskId(singleTaskId));
        dispatch(setCalculationDone(false));
      },
    );
  }
};

export const getPostalCodeFromCoords = (latitude: number, longitude: number): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/geo/postal_code/', { latitude, longitude },
).then(
  (response: { postalCode: string | null }) => Promise.resolve(response),
  () => Promise.resolve({ postalCode: null }),
);

export const acceptWsCalculation = (singleRateId: any, command: any, data: any): ThunkAction<void> => (dispatch, getState) => {
  const { calculation: { taskId } } = getState();
  if (taskId === singleRateId) {
    if (command === 'results') {
      dispatch(addCalculationResults(data));
    } else if (command === 'done') {
      // dispatch(setCalculationPollingTimer(null));
      dispatch(setCalculationDone(true));
    }
  }
};
