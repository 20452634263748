import React from 'react';
import Header from './components/Header';
import TariffBlock from './components/TariffBlock';
import Creation from './components/Creation';
import Why from './components/Why';
import Clients from './components/Clients';
import AdditionalServices from './components/AdditionalServices';
import Features from './components/Features';
import Callback from './components/Callback';
import Footer from './components/Footer';
import useSelector from '../../utils/hooks/useSelector';
import CalculationResultList from './components/calculation/CalculationResultList';
import RoundedBackground from '../../components/RoundedBackground';
import TrackingBlock from './components/TrackingBlock';

function Index() {
  const { taskId } = useSelector((state) => state.calculation);

  return (
      <div className="pls-index">
        <Header/>
        <div id="calculation"/>
        {!!taskId && <CalculationResultList onSelect={() => {
        }}/>}
        <TrackingBlock />
        <TariffBlock/>
        <Creation/>
        <RoundedBackground>
          <Why/>
          <Clients/>
        </RoundedBackground>
        <AdditionalServices/>
        <Features/>
        <Callback/>
        <Footer/>
      </div>
  );
}

export default Index;
