import React from 'react';
import {
  Col, Row, Select, Spin,
} from 'antd';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import useDispatch from '../utils/hooks/useDispatch';
import useSelector from '../utils/hooks/useSelector';
import { GeoSuggest } from '../redux/calculation/types';
import { RefSelectProps } from 'antd/lib/select';
import {
  geoSuggest,
  getPostalCodeFromCoords,
  setReceiverAddress,
  setSenderAddress
} from '../redux/calculation/action-creators';
import { ErrorContext } from './ErrorProvider';


interface AddressFormProps {
  side: 'sender' | 'receiver';
}

function AddressForm({ side }: AddressFormProps) {
  const dispatch = useDispatch();
  const { senderAddress, receiverAddress } = useSelector((state) => state.calculation);
  const errors = React.useContext(ErrorContext);

  const [fetching, setFetching] = React.useState(false);
  const [result, setResult] = React.useState<anyObject[]>([]);
  const [geoObjects, setGeoObjects] = React.useState<GeoSuggest[]>([]);
  const selectRef = React.useRef<null | RefSelectProps>();
  const [markerLatLng, setMarkerLatLng] = React.useState<any>({
    lat: null,
    lng: null,
  });

  const error = React.useMemo(() => {
    if (side === 'sender') {
      return errors.error['sender'];
    }

    if (side === 'receiver') {
      return errors.error['receiver'];
    }
  }, [errors, side]);


  const handleSelect = React.useCallback(
      async (value) => {
        let result = null;
        if (geoObjects.length > value) {
          result = geoObjects[value];
          if (!result.postcode && result.latitude && result.longitude) {
            const postalCode = await dispatch(getPostalCodeFromCoords(result.latitude, result.longitude));
            result.postcode = postalCode.postalCode;
          }
          setMarkerLatLng({
            lat: result.latitude,
            lng: result.longitude,
          });
          if (side === 'sender') dispatch(setSenderAddress(result));
          else if (side === 'receiver') dispatch(setReceiverAddress(result));

          if (error) {
            errors.setErrors({[side]: undefined});
          }
        }
        if (selectRef.current) {
          selectRef.current.blur();
        }
      },
      [dispatch, selectRef, geoObjects, side, error],
  );


  function fetchLocation(value: string) {
    const isShorterThanMinLength = value.length < 2;

    if (isShorterThanMinLength || value.length === 0) {
      return;
    }

    setResult([]);
    setGeoObjects([]);
    setFetching(true);
    dispatch(geoSuggest(value)).then(
        ({ suggestions }) => {
          console.debug(suggestions);
          setGeoObjects(suggestions);
          const data = suggestions.map((_) => ({ address: _.value }));
          setResult(data);
          setFetching(false);
        },
    );
  }

  const debouncedFetchLocation = React.useCallback(
      debounce(fetchLocation, 800),
      [fetchLocation],
  );

  const handleSearch = React.useCallback(
      (newSearchValue) => {
        debouncedFetchLocation(newSearchValue);
      },
      [debouncedFetchLocation],
  );


  return (
    <Row className={classNames({ 'has-error': !!error })}>
      <div className="ant-col ant-col-xs-24 pls-z-index-10 pls-relative">
        <label
          className=""
          title={side === 'sender' ? 'Город отправителя' : 'Город получателя'}
        >
          {side === 'sender' ? 'Город отправителя' : 'Город получателя'}
        </label>
      </div>
      <Col span={24}>
        <div>
          <Select
            value={side === 'sender' ? senderAddress?.value : receiverAddress?.value}
            defaultActiveFirstOption={false}
            notFoundContent={fetching ? <Spin size="small"/> : 'Не найдено'}
            onSearch={handleSearch}
            onSelect={handleSelect}
            filterOption={false}
            showArrow={false}
            showSearch
            ref={(el) => selectRef.current = el}
            className="pls-w-100 pls-z-index-10"
            size="large"
            placeholder="Москва"
          >
            {result.map((item, index) => (
              <Select.Option
                key={item.address}
                value={index}
                className="pls-calculation__select-option"
              >
                {item.address}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Col>
      {!!error && <Col xs={24} className="pls-text-red pls-fs-sm pls-z-index-10 pls-relative">
        {error}
      </Col>}
    </Row>
  );
}

export default AddressForm;
