import React from 'react';
import useDispatch from '../../../utils/hooks/useDispatch';
import { TrackingItem } from '../../../redux/tracking/types';
import { Button, Col, Input, Row, Spin, Timeline } from 'antd';
import classNames from 'classnames';
import { loadTracking } from '../../../redux/tracking/action-creators';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

const renderDate = (date: string) => {
  const _date = moment(date);
  if (_date.isValid()) return _date.format('DD MMMM YYYY HH:mm');
  return '';
};

function TrackingBlock() {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<null | string>(null);
  const [tracking, setTracking] = React.useState<TrackingItem[]>([]);
  const [inited, setInited] = React.useState(false);
  const [number, setNumber] = React.useState<string>();
  const [notFound, setNotFound] = React.useState(false);

  const handleLoad = React.useCallback(
      () => {
        setLoading(true);
        setError(null);
        setInited(true);
        setNotFound(false);
        dispatch(loadTracking(number || '')).then(
            (tracking) => setTracking(tracking),
            (errors) => {
              if (errors.trackingNumber) setError(errors.trackingNumber);
              else setNotFound(true);
            }
        ).finally(() => setLoading(false));
      },
      [dispatch, number],
  );


  return (
      <div className="pls-tracking" id="tracking">
        <h2 className="pls-text-center pls-mt-md-100 pls-mb-5">
          Отследить отправку
        </h2>

        <div className="pls-tracking-form">
          <Row className={classNames({ 'has-error': !!error })}>
            <Col xs={24} className="pls-z-index-10 pls-relative">
              <label
                  className=""
                  title={'Номер отправки'}
              >
                Номер отправки
              </label>
            </Col>
            <Col span={24}>
              <Input
                  placeholder="Введите номер"
                  value={number}
                  onChange={({ target: { value } }) => setNumber(value)}
              />
            </Col>
            {!!error && <Col xs={24} className="pls-text-red pls-fs-sm pls-z-index-10 pls-relative">
              {error}
            </Col>}
          </Row>

          <div style={{ paddingTop: 32 }}>
            <Button
                type="primary"
                style={{ height: 58 }}
                loading={loading}
                className="pls-button pls-w-100 pls-z-index-10 pls-relative"
                onClick={handleLoad}
            >
              Отследить
            </Button>
          </div>
        </div>

        {inited && (
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>}
                spinning={loading}
                delay={500}
            >
              <div className="pls-tracking-list">
                {notFound && (
                    <div className="pls-text-center pls-text-dark-blue pls-opacity-80">Отправление не найдено</div>
                )}
                <Timeline>
                  {tracking.map((_) => (
                      <Timeline.Item>
                        {renderDate(_.date)} {_.description}
                      </Timeline.Item>
                  ))}
                </Timeline>

              </div>
            </Spin>
        )}
      </div>
  );
}

export default TrackingBlock;
