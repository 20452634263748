import React from 'react';
import RounderBackgroundSvg from '../assets/images/rounded-backgound.svg';

type RoundedBackgroundProps = {
  children: any
}

function RoundedBackground({ children }: RoundedBackgroundProps) {
  return (
      <div className="pls-rounded-background">
        <svg className="pls-rounded-background-top" viewBox="0 0 1440 202" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0C464 185.1 997 166.071 1440 0V202H0V0Z" fill="white"/>
        </svg>
        <div className="pls-rounded-background-content">
          {children}
        </div>
        <svg className="pls-rounded-background-bottom" viewBox="0 0 1440 290" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H720H1440V207.379C948.5 508.501 741 -124.998 0 49.2549V0Z" fill="white"/>
        </svg>

      </div>
  );
}

export default RoundedBackground;
