import Centrifuge from 'centrifuge';

export const SET_OPERATORS = 'SET_OPERATORS';
export const SET_CENT = 'SET_CENT';
export const SET_SENDER_ADDRESS = 'SET_SENDER_ADDRESS';
export const SET_RECEIVER_ADDRESS = 'SET_RECEIVER_ADDRES';
export const ADD_PACKAGE = 'ADD_PACKAGE';
export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const REPLACE_PACKAGE = 'REPLACE_PACKAGE';
export const SET_CALCULATION_RUNNING = 'SET_CALCULATION_RUNNING';
export const SET_CALCULATION_TASK_ID = 'SET_CALCULATION_TASK_ID';
export const SET_CALCULATION_RESULTS = 'SET_CALCULATION_RESULTS';
export const ADD_CALCULATION_RESULTS = 'ADD_CALCULATION_RESULTS';
export const RESET_CALCULATION = 'RESET_CALCULATION';
export const SET_WEIGHT = 'SET_WEIGHT';
export const UPDATE_CARGO = 'UPDATE_CARGO';
export const SET_ORDERING = 'SET_ORDERING';
export const SET_CALCULATION_DONE = 'SET_CALCULATION_DONE';
export const UPDATE_CURRENT_DELIVERY_SERVICE_FILTER = 'UPDATE_CURRENT_DELIVERY_SERVICE_FILTER';
export const UPDATE_SERVICES_FILTER = 'UPDATE_SERVICES_FILTER';
export const SET_ADDITIONAL_SERVICES = 'SET_ADDITIONAL_SERVICES';
export const SWITCH_CURRENT_FILTER = 'SWITCH_CURRENT_FILTER';

export enum AdditionalServiceCode {
  INSURANCE,
  CARGO_LOADING,
  CARGO_UNLOADING,
  RETURN,
  BY_HAND
}


export type AdditionalServices = Array<AdditionalServiceCode>;

export type OperatorSettings = {
  loadingUnloadingEnabled: boolean,
  loadingUnloadingFreeLimit: number,
  autoLoadingUnloading: boolean,
};

export type Operator = {
  key: string,
  title: string,
  icon: string,
  rating: number
  settings: OperatorSettings,
}

export type GeoSuggest = {
  value: string,
  postcode: string | null,
  iso: string,
  region: string | null,
  district: string | null,
  city: string | null,
  street: string | null,
  house: string | null,
  latitude: number | null,
  longitude: number | null,
};

export interface Package {
  width?: number | undefined,
  height?: number | undefined,
  length?: number | undefined,
  weight?: number | undefined,
}

export enum AdditionalServiceStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  NEED_CONFIRM = 'NEED_CONFIRM'
}

export enum RateAdditionalServiceCode {
  INSURANCE = 'INSURANCE',
  CARGO_LOADING = 'CARGO_LOADING',
  CARGO_UNLOADING = 'CARGO_UNLOADING',
  RETURN = 'RETURN',
  BY_HAND = 'BY_HAND'
}

export type AdditionalService = {
  code: RateAdditionalServiceCode;
  status: AdditionalServiceStatus;
  amount: number;
};

export interface SingleRate {
  rateResultId: string,
  deliveryService: string,
  code: string,
  name: string,
  price: string,
  minDays: number,
  maxDays: number,
  pickupExtra: string,
  deliveryExtra: string,
  listPrice: number,
  additionalServices: AdditionalService[],
}

export type CalculationFilter = {
  deliveryServices: Array<string>,
  services: string[],
};

export type CurrentCalculationFilter = {
  deliveryServices: {
    touched: boolean,
    open: boolean,
    values: Array<string>
  },
  services: {
    touched: boolean,
    open: boolean,
    values: Array<string>
  }
};

export interface SetOperators {
  type: typeof SET_OPERATORS,
  operators: Operator[],
}

export interface SetCent {
  type: typeof SET_CENT,
  cent: Centrifuge,
}


export interface SetSenderAddress {
  type: typeof SET_SENDER_ADDRESS,
  address: GeoSuggest
}

export interface SetReceiverAddress {
  type: typeof SET_RECEIVER_ADDRESS,
  address: GeoSuggest,
}

export interface AddPackage {
  type: typeof ADD_PACKAGE
}

export interface DeletePackage {
  type: typeof DELETE_PACKAGE,
  packageId: number
}

export interface ReplacePackage {
  type: typeof REPLACE_PACKAGE,
  packageId: number,
  package: Package
}

export interface ResetCalculation {
  type: typeof RESET_CALCULATION,
}

export interface SetCalculationTaskId {
  type: typeof SET_CALCULATION_TASK_ID,
  taskId: string
}

export interface SetCalculationResults {
  type: typeof SET_CALCULATION_RESULTS,
  results: SingleRate[]
}

export interface AddCalculationResults {
  type: typeof ADD_CALCULATION_RESULTS,
  results: SingleRate[],
}

export interface SetWeight {
  type: typeof SET_WEIGHT,
  weight: string,
}

export interface UpdateCargo {
  type: typeof UPDATE_CARGO,
  cargo: Partial<Package>
}

export interface SetOrdering {
  type: typeof SET_ORDERING,
  ordering: 'price' | 'minDays',
}

export interface SetCalculationDone {
  type: typeof SET_CALCULATION_DONE,
  done: boolean,
}

export interface UpdateCurrentDeliveryServiceFilter {
  type: typeof UPDATE_CURRENT_DELIVERY_SERVICE_FILTER,
  deliveryServices: string[],
}

export interface UpdateServicesFilter {
  type: typeof UPDATE_SERVICES_FILTER,
  services: string[],
  initial?: boolean,
}

export interface SetAdditionalServices {
  type: typeof SET_ADDITIONAL_SERVICES,
  services: AdditionalServices,
}

export interface SwitchCurrentFilter {
  type: typeof SWITCH_CURRENT_FILTER,
  filter: 'deliveryServices'
}



export type CalculationActions = SetOperators
    | SetCent
    | SetSenderAddress
    | SetReceiverAddress
    | AddPackage
    | DeletePackage
    | ReplacePackage
    | ResetCalculation
    | SetCalculationTaskId
    | SetCalculationResults
    | AddCalculationResults
    | SetWeight
    |UpdateCargo | SetOrdering | SetCalculationDone | UpdateCurrentDeliveryServiceFilter | UpdateServicesFilter |
    SetAdditionalServices | SwitchCurrentFilter;
