type smoothScrollToFunction = () => number;

export const smoothScroll = (to: number | smoothScrollToFunction = 0, duration = 70) => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    let _to = to;
    if (typeof _to === 'function') _to = _to();
    const supportPageOffset = window.pageXOffset !== undefined;
    const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
    const scroll = {
      x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
      y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop,
    };

    if (duration <= 0) return;
    const difference = _to - scroll.y;
    const perTick = (difference / duration) * 10;

    setTimeout(() => {
      scroll.y += perTick;
      window.scrollTo(0, scroll.y);
      if (scroll.y === _to) return;
      smoothScroll(_to, duration - 10);
    }, 10);
  }
};


export const scrollToElement = (element: HTMLElement, headerOffset = 0, timeout = 100, scrollFunction = smoothScroll) => {
  if (typeof window === 'undefined' || typeof document === 'undefined') return;
  setTimeout(() => {
    if (typeof scrollFunction === 'function') {
      scrollFunction(element.getBoundingClientRect().top - headerOffset);
    } else {
      window.scrollTo(0, element.getBoundingClientRect().top - headerOffset);
    }
  }, timeout);
};
