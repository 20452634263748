import React from 'react';

function Rosneft() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 146.836 96.561">
        <defs/>
        <path fill="#d9bb6c"
              d="M71.014 65.601h5.469V36.084h-5.469V65.6zm-6.56 0h5.468V29.524h-5.468V65.6zm-6.559 0h5.467V36.084h-5.467V65.6z"/>
        <path
            d="M89.598 32.8l-5.463 5.466V19.665h5.468m-6.561 19.692l-5.468 5.47V13.1h5.468m-12.028 21.89h5.47V6.555h-5.47v28.434zm-6.56-6.56h5.466V0h-5.466v28.429zm-6.559 6.56h5.465V6.555h-5.465v28.434zM56.8 44.826l-5.467-5.469V13.1H56.8m-12.026 6.566v13.134l5.465 5.468V19.665M14.352 72.336c2.736 0 4.972 2.202 4.972 4.892v1.05c0 2.288-1.619 4.22-3.782 4.748l4.501 5.562h-6.5L9.157 83.17H6.5v5.418H0v-16.25l14.352-.001M6.5 79.377h5.523c.82 0 1.492-.66 1.492-1.467v-.313c0-.81-.67-1.468-1.492-1.468H6.5m40.115 12.457h9.21c2.83 0 5.147-2.314 5.147-5.144 0-2.833-2.317-5.147-5.148-5.147h-6.436a.878.878 0 01-.876-.873v-.42c0-.48.393-.873.876-.873h4.207c.482 0 .875.393.875.873v.208h6.494c-.144-2.705-2.4-4.873-5.14-4.873h-9.209c-2.828 0-5.146 2.314-5.146 5.146 0 2.83 2.318 5.145 5.146 5.145h6.44c.48 0 .875.393.875.875v.418a.88.88 0 01-.876.875h-4.209a.878.878 0 01-.875-.875v-.208h-6.493c.142 2.702 2.4 4.874 5.138 4.874m29.524 0h6.499v-16.25h-6.499v9.366l-7.585-9.366h-6.499v16.25h6.5V79.22m34.062 9.366h6.501v-5.96h9.74v-4.332l-9.74-.027V76.67h10.959v-4.333h-17.46m-82.57 8.102c0 5.322 3.478 8.148 10.35 8.148s10.351-2.826 10.351-8.148c0-5.324-3.479-8.102-10.351-8.102-6.872 0-10.35 2.778-10.35 8.102m10.35-4.311c2.112 0 3.838 1.468 3.838 3.268v2.13c0 1.8-1.726 3.272-3.838 3.272-2.11 0-3.838-1.473-3.838-3.272v-2.13c0-1.8 1.728-3.268 3.838-3.268m53.507 12.458h17.991v-4.332H90.403v-1.626h9.739v-4.333l-9.739-.027V76.67h10.959v-4.334H83.904m42.758 16.25h6.502V76.67h5.699v-4.332h-18.064v4.332h5.863"/>
      </svg>
  );
}

export default Rosneft;
