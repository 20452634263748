import React, { useCallback } from 'react';
import Select from 'antd/es/select';
import useDispatch from '../../../../utils/hooks/useDispatch';
import useSelector from '../../../../utils/hooks/useSelector';
import { setOrdering } from '../../../../redux/calculation/action-creators';

function CalculationResultListHeader() {
  const dispatch = useDispatch();
  const { currentOrdering } = useSelector((state) => state.calculation);

  const handleOrdering = useCallback(
    (value) => {
      dispatch(setOrdering(value));
    },
    [dispatch],
  );


  return (
    <div className="pls-d-flex pls-justify-content-between pls-pl-md-5 pls-pb-3 pls-align-items-center">
      <h4 className="pls-text-dark-blue">Выберите тариф</h4>
      <Select value={currentOrdering} onChange={handleOrdering} style={{ width: 240 }}>
        <Select.Option value="price">По стоимости</Select.Option>
        <Select.Option value="minDays">По скорости</Select.Option>
      </Select>
    </div>
  );
}

export default CalculationResultListHeader;
