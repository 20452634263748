import React from 'react';
import { Provider } from 'react-redux';
import MainWrapper from './MainWrapper';
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

function App({ store } : any) {
  return (
      <Provider store={store}>
        <MainWrapper />
      </Provider>
  );
}

export default App;
