export interface SettingsState {
  width: number,
  height: number,
  mobile: boolean,
}

const initialState: SettingsState = {
  width: window.innerWidth,
  height: window.innerHeight,
  mobile: window.innerWidth < 991,
};

export default function (state = initialState, action: any): SettingsState {
  switch (action.type) {
    case 'WINDOW_WIDTH':
      return { ...state, width: action.width, mobile: window.innerWidth < 991 };
    case 'WINDOW_HEIGHT':
      return { ...state, height: action.height, };
    default:
      return state;
  }
}
