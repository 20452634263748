import React, { useCallback, useMemo } from 'react';
import Row from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';
import { Collapse } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import useDispatch from '../../../../utils/hooks/useDispatch';
import useMobile from '../../../../utils/hooks/useMobile';
import useSelector from '../../../../utils/hooks/useSelector';
import {
  AdditionalServiceCode,
  AdditionalServiceStatus,
  RateAdditionalServiceCode
} from '../../../../redux/calculation/types';
import { ADDITIONAL_SERVICES } from './constants';
import {
  setAdditionalServices, switchCurrentFilters,
  updateCurrentDeliveryServicesFilter,
  updateServicesFilter
} from '../../../../redux/calculation/action-creators';

interface CalculationResultFilterProps {
  additionalServicesEnabled?: boolean,
}

function CalculationResultFilter({ additionalServicesEnabled }: CalculationResultFilterProps) {
  const dispatch = useDispatch();
  const isMobile = useMobile();

  const { availableFilters, currentFilters, results, operators } = useSelector((state) => state.calculation);
  const allDeliveryServices = availableFilters.deliveryServices.length === currentFilters.deliveryServices.values.length;
  const allServices = availableFilters.services.length === currentFilters.services.values.length;

  const handleDeliveryServiceChange = useCallback(
    ({ target: { value } }: CheckboxChangeEvent) => {
      const tmp = currentFilters.deliveryServices.values.slice();
      const curIndex = tmp.findIndex((_) => _ === value);
      if (curIndex > -1) {
        tmp.splice(curIndex, 1);
      } else {
        tmp.push(value);
      }
      dispatch(updateCurrentDeliveryServicesFilter(tmp));
    },
    [dispatch, currentFilters],
  );

  const handleAllDeliveryServices = useCallback(
    () => {
      if (!!currentFilters.deliveryServices.values.length || allDeliveryServices) {
        dispatch(updateCurrentDeliveryServicesFilter([]));
      } else {
        dispatch(updateCurrentDeliveryServicesFilter(availableFilters.deliveryServices));
      }
    },
    [allDeliveryServices, availableFilters.deliveryServices, currentFilters.deliveryServices],
  );

  const handleServicesChange = useCallback(
    ({ target: { value } }: CheckboxChangeEvent) => {
      const tmp = currentFilters.services.values.slice();
      const curIndex = tmp.findIndex((_) => _ === value);
      if (curIndex > -1) {
        tmp.splice(curIndex, 1);
      } else {
        tmp.push(value);
      }
      dispatch(updateServicesFilter(tmp));
      dispatch(setAdditionalServices(tmp.map((_) => {
        switch (_) {
          case RateAdditionalServiceCode.CARGO_LOADING:
            return AdditionalServiceCode.CARGO_LOADING;
          case RateAdditionalServiceCode.CARGO_UNLOADING:
            return AdditionalServiceCode.CARGO_UNLOADING;
          case RateAdditionalServiceCode.RETURN:
            return AdditionalServiceCode.RETURN;
          case RateAdditionalServiceCode.BY_HAND:
            return AdditionalServiceCode.BY_HAND;
        }
        return 1;
      })));
    },
    [dispatch, currentFilters],
  );

  const handleAllServices = useCallback(
    () => {
      if (!!currentFilters.services.values.length || allServices) {
        dispatch(updateServicesFilter([]));
        dispatch(setAdditionalServices([]));
      } else {
        dispatch(updateServicesFilter(availableFilters.services));
        dispatch(setAdditionalServices(availableFilters.services.map((_) => {
          switch (_) {
            case RateAdditionalServiceCode.CARGO_LOADING:
              return AdditionalServiceCode.CARGO_LOADING;
            case RateAdditionalServiceCode.CARGO_UNLOADING:
              return AdditionalServiceCode.CARGO_UNLOADING;
            case RateAdditionalServiceCode.RETURN:
              return AdditionalServiceCode.RETURN;
            case RateAdditionalServiceCode.BY_HAND:
              return AdditionalServiceCode.BY_HAND;
          }
          return 1;
        })));
      }
    },
    [allServices, availableFilters.services, currentFilters.services],
  );

  const handleCollapseChange = useCallback(
    (filter) => {
      dispatch(switchCurrentFilters(filter));
    },
    [dispatch],
  );

  // @ts-ignore
  const openedFilters = useMemo(() => Object.keys(currentFilters).filter((_) => currentFilters[_].open),
    [currentFilters]);

  const availableDeliveryServices = useMemo(() => availableFilters.deliveryServices.map((_) => {
    const serviceRates = results.filter((rate) => rate.deliveryService === _);
    let count = serviceRates.length;
    return {
      title: operators[_]?.title || _,
      value: _,
      count,
      disabled: count === 0,
    };
  }), [availableFilters.deliveryServices, results, currentFilters.services]);

  const availableServices = React.useMemo(() => {
    const services: anyObject[] = [];
    availableFilters.services.forEach((_) => {
      const serviceRender = ADDITIONAL_SERVICES[_];
      if (serviceRender && (!serviceRender.isWip)) {
        services.push({
          title: ADDITIONAL_SERVICES[_].title,
          value: _,
          count: results.filter((r) => r.additionalServices.find((as) => as.code === _ && as.status !== AdditionalServiceStatus.NOT_AVAILABLE)).length,
        });
      }
    });
    return services;
  }, [availableFilters.services, results]);

  return (
    <Row className={isMobile ? 'pls-pl-md-4 pls-pt-md-4' : 'pls-pl-md-4 pls-py-md-4'}>
      <Col xs={24}>
        <Collapse className="ant-collapse__right-arrow ant-collapse__transparent" activeKey={openedFilters}>
          <Collapse.Panel
            key="deliveryServices"
            header={<div className="pls-text-dark-blue" onClick={() => handleCollapseChange('deliveryServices')}>Компании</div>}
          >
            <div className="pls-d-flex pls-justify-content-between">
              <Checkbox
                checked={allDeliveryServices}
                indeterminate={!!currentFilters.deliveryServices.values.length && !allDeliveryServices}
                onChange={handleAllDeliveryServices}
                className="pls-mb-2"
              >
                Все
              </Checkbox>
              <span className="pls-text-muted">
                {availableDeliveryServices.reduce((_, __) => _ + __.count, 0)}
              </span>
            </div>
            {availableDeliveryServices.map((_) => (
              <div className="pls-d-flex pls-justify-content-between">
                <Checkbox
                  value={_.value}
                  checked={currentFilters.deliveryServices.values.includes(_.value)}
                  onChange={handleDeliveryServiceChange}
                  className="pls-mb-2"
                  disabled={_.disabled}
                >
                  {_.title}
                </Checkbox>
                <span className="pls-text-muted">
                  {_.count}
                </span>
              </div>
            ))}
          </Collapse.Panel>
          {additionalServicesEnabled && availableServices.length > 0
          && (
          <Collapse.Panel
            key="services"
            header={<div className="pls-text-dark-blue" onClick={() => handleCollapseChange('services')}>Дополнительные услуги</div>}
          >
            <div className="pls-d-flex pls-justify-content-between">
              <Checkbox
                checked={allServices}
                indeterminate={!!currentFilters.services.values.length && !allServices}
                onChange={handleAllServices}
                className="pls-mb-2"
              >
                Все
              </Checkbox>
            </div>
            {availableServices.map((_) => (
              <div className="pls-d-flex pls-justify-content-between">
                <Checkbox
                  value={_.value}
                  checked={currentFilters.services.values.includes(_.value)}
                  onChange={handleServicesChange}
                  className="pls-mb-2"
                >
                  {_.title}
                </Checkbox>
                <span className="pls-text-muted">
                  {_.count}
                </span>
              </div>
            ))}
          </Collapse.Panel>
          )}
        </Collapse>
      </Col>
    </Row>
  );
}

export default CalculationResultFilter;
