import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router';
import Index from './pages/index';
import TrackingPage from './pages/tracking';

function Router() {
  return (
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Index} exact />
          <Route path="/tracking" component={TrackingPage} exact />
          <Route path="/track" component={TrackingPage} exact />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
  );
}

export default Router;
