import React from 'react';
import { Button, Col, Row } from 'antd';
import Icon from '@ant-design/icons';
import { Step1, Step2, Step3, Step4, Step5, Step6 } from '../../../components/icons/Steps';
import LeaderLine from 'leader-line-new';
import { Link } from 'react-router-dom';

function Creation() {
  const leaderLines = React.useRef<LeaderLine[]>([]);

  // React.useEffect(() => {
  //   const step1 = document.getElementById('creation-step-1');
  //   const step2 = document.getElementById('creation-step-2');
  //   const step3 = document.getElementById('creation-step-3');
  //   const step4 = document.getElementById('creation-step-4');
  //   const step5 = document.getElementById('creation-step-5');
  //   const step6 = document.getElementById('creation-step-6');
  //   const _: LeaderLine[] = [];
  //
  //   leaderLines.current.forEach((line) => {
  //     line.remove();
  //   })
  //
  //   const leaderLineOptions: LeaderLine.Options = {
  //     path: 'arc',
  //     startPlug: 'behind',
  //     endPlug: 'behind',
  //     dash: true,
  //   };
  //
  //   if (step1 && step2) _.push(new LeaderLine(step1, step2, leaderLineOptions));
  //   if (step2 && step3) _.push(new LeaderLine(step2, step3, leaderLineOptions));
  //   if (step3 && step4) _.push(new LeaderLine(step3, step4, { ...leaderLineOptions, startSocketGravity: 200, endSocketGravity: 300, startSocket: 'right', endSocket: 'top' }));
  //   if (step4 && step5) _.push(new LeaderLine(step4, step5, leaderLineOptions));
  //   if (step5 && step6) _.push(new LeaderLine(step5, step6, leaderLineOptions));
  //
  //   leaderLines.current = _;
  // }, []);


  return (
      <div className="pls-creation" id="work">
        <h2 className="pls-text-center pls-mt-md-100 pls-mb-5">
          Единый договор, а отправляете
          <br/>
          Всеми службами доставки.
        </h2>
        <div className="pls-text-center pls-text-dark-blue pls-opacity-80">
          Отправки, отслеживание, расчеты, возвраты - все в
          <br/>
          одном окне с простым интерфейсом. Чат с
          <br/>
          персональным менеджером службы доставки в
          <br/>
          личном кабинете.
        </div>

        <Row className="pls-creation-step-list">
          <Col xs={24} md={8} className="pls-text-center pls-creation-step">
            <div className="pls-creation-step-description">
              Создаете в ЛК заказ /<br/> заказ падает в ЛК с сайта
            </div>
            <div className="pls-creation-step-image" id="creation-step-1">
              <Icon component={Step1}/>
            </div>
          </Col>
          <Col xs={24} md={8} className="pls-text-center pls-creation-step">
            <div className="pls-creation-step-description">Вызываете курьера и<br/> печатаете накладную</div>
            <div className="pls-creation-step-image" id="creation-step-2">
              <Icon component={Step2}/>
            </div>
          </Col>
          <Col xs={24} md={8} className="pls-text-center pls-creation-step">
            <div className="pls-creation-step-description">Курьер службы доставки<br/> забирает заказы</div>
            <div className="pls-creation-step-image" id="creation-step-3">
              <Icon component={Step3}/>
            </div>
          </Col>
          <Col xs={24} md={8} className="pls-text-center pls-creation-step">
            <div className="pls-creation-step-image" id="creation-step-4">
              <Icon component={Step4}/>
            </div>
            <div className="pls-creation-step-description">В ЛК отслеживаем<br/> все заказы</div>
          </Col>
          <Col xs={24} md={8} className="pls-text-center pls-creation-step">

            <div className="pls-creation-step-image" id="creation-step-5">
              <Icon component={Step5}/>
            </div>
            <div className="pls-creation-step-description">Посылка у покупателя /<br/> возврат на склад</div>
          </Col>
          <Col xs={24} md={8} className="pls-text-center pls-creation-step">
            <div className="pls-creation-step-image" id="creation-step-6">
              <Icon component={Step6}/>
            </div>
            <div className="pls-creation-step-description">Перечисление наложенного платежа <br/> при продаже</div>
          </Col>
        </Row>


        <div className="pls-text-center pls-mt-6">
          <Link to="https://lk.belkapost.ru">
            <Button className="pls-button pls-button-rounded pls-button-red">
              Попробовать
            </Button>
          </Link>
        </div>
      </div>
  );
}

export default Creation;
