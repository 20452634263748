import React from 'react';

function Why() {
  return (
      <div className="pls-why" id="benefits">
        <h2 className="pls-text-center">
          Зачем вам агрегатор служб доставки?
        </h2>
        <div className="pls-text-center pls-text-dark-blue pls-opacity-80">
          Для каждого заказа система предложит самую <br/> низкую цену. Переключение между службами <br/> доставки в 1
          клик.
        </div>

        <div className="pls-d-flex pls-justify-content-center">
          <div className="pls-why-grid">
            <div className="pls-why-grid-item">
              <div className="pls-why-grid-item-number">
                1.
              </div>
              <div className="pls-why-grid-item-description">
                Добавляйте заказы по API, готовыми решениями для CMS или вручную.
              </div>
            </div>
            <div className="pls-why-grid-item pls-why-grid-item-alternative">
              <div className="pls-why-grid-item-number">
                2.
              </div>
              <div className="pls-why-grid-item-description">
                Доставка по всему Миру, более 300&nbsp;000 населенных пунктов.
              </div>
            </div>
            <div className="pls-why-grid-item">
              <div className="pls-why-grid-item-number">
                3.
              </div>
              <div className="pls-why-grid-item-description">Доставка день-в-день, а для интернет-магазинов примерка и
                частичный выкуп.
              </div>
            </div>

            <div className="pls-why-grid-item pls-why-grid-item-alternative">
              <div className="pls-why-grid-item-number">
                4.
              </div>
              <div className="pls-why-grid-item-description">Наложенные платежи переводятся на счет ЕЖЕДНЕВНО.</div>
            </div>
            <div className="pls-why-grid-item ">
              <div className="pls-why-grid-item-number">
                5.
              </div>
              <div className="pls-why-grid-item-description">Доставка даже крупногабаритных грузов.</div>
            </div>
            <div className="pls-why-grid-item pls-why-grid-item-alternative">
              <div className="pls-why-grid-item-number">
                6.
              </div>
              <div className="pls-why-grid-item-description">Более 7000 собственных и партнерских офисов по всему Миру.
              </div>
            </div>

          </div>
        </div>
      </div>
  );
}

export default Why;
