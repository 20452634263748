import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Row from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';
import List from 'antd/es/list';
import useSelector from '../../../../utils/hooks/useSelector';
import { AdditionalServiceStatus } from '../../../../redux/calculation/types';
import { sortArrayOfObject } from '../../../../utils/sort';
import SingleTariff from '../../../../components/SingleTariff';
import useMobile from '../../../../utils/hooks/useMobile';
import CalculationResultFilter from './CalculationResultFilter';
import CalculationResultListHeader from './CalculationResultListHeader';

function CalculationResultList() {
  const {
    results, currentFilters, currentOrdering, calculationDone,
  } = useSelector((state) => state.calculation);
  const isMobile = useMobile();


  const currentRates = useMemo(() => {
    let tmp = results.slice();
    const deliveryServices = currentFilters.deliveryServices.values;
    tmp = tmp.filter((r) => deliveryServices.includes(r.deliveryService));
    const services = currentFilters.services.values;
    if (services.length) {
      tmp = tmp.filter(
          (r) => services.every(
              (as) => r.additionalServices.find((ras) => ras.code === as && ras.status !== AdditionalServiceStatus.NOT_AVAILABLE),
          ),
      );
    }

    tmp = sortArrayOfObject(tmp, currentOrdering);
    return tmp;
  }, [currentFilters, results, currentOrdering]);

  // const showCdekAlert = React.useMemo(() => (user?.accountId ? CDEK.includes(user.accountId) : false), [user]);

  const handleCallback = React.useCallback(() => {
    // @ts-ignore
    const buttons: any[] = window.BX?.SiteButton?.buttons?.list || [];

    const crmform = buttons.find((_) => _.id === 'crmform');
    if (crmform) {
      crmform.onclick();
      return;
    }
    const callback = buttons.find((_) => _.id === 'callback');
    if (callback) {
      callback.onclick();
      return ''
    }

    // @ts-ignore
    window.BX?.LiveChat?.openLiveChat()

  }, []);


  return (
      <div className="pls-calculation-result">
        <Row>
          <Col xs={24} md={7} className="pls-border-right pls-d-flex pls-flex-column">
            <CalculationResultFilter additionalServicesEnabled={false}/>
          </Col>
          <Col xs={24} md={17} className="pls-pl-0 pls-py-md-4">
            <List
                header={isMobile ? undefined : <CalculationResultListHeader/>}
                dataSource={currentRates}
                split={false}
                loading={!calculationDone && !results.length}
            >
              {currentRates.map((rate) => (
                  <SingleTariff
                      code={rate.code}
                      minDays={rate.minDays}
                      maxDays={rate.maxDays}
                      name={rate.name}
                      price={rate.price}
                      deliveryService={rate.deliveryService}
                      rateResultId={rate.rateResultId}
                      listPrice={rate.listPrice}
                      localDelivery={false}
                      onClick={handleCallback}
                  />
              ))}
              {currentRates.length === 0 && !!results.length && (
                  <div className="pls-text-center pls-text-dark-blue">
                    По вашему запросу результатов не найдено
                  </div>
              )}
            </List>
          </Col>
        </Row>
      </div>
  );
}

CalculationResultList.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default CalculationResultList;
