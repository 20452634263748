import { TrackingItem } from './types';
import { ThunkAction } from '../../types';

export const loadTracking = (trackingNumber: string): ThunkAction<Promise<TrackingItem[]>> => (dispatch, getState, http) => http.get(
  '/api/v1/tracking/landing/',
  {
    tracking_number: trackingNumber,
  },
).then(
  ({ tracking }: { tracking: TrackingItem[]}) => Promise.resolve(tracking),
);
