import { Col, List, Row } from 'antd';
import React from 'react';
import { pluralize } from '../utils/pluralize';
import { money } from '../utils/format';
import useSelector from '../utils/hooks/useSelector';
import classNames from 'classnames';

export type SingleTariffProps = {
  rateResultId: string,
  deliveryService: string,
  code: string,
  name: string,
  price: string,
  minDays: number,
  maxDays: number,
  listPrice: number,
  localDelivery: boolean,
  onClick?: () => void,
}

function SingleTariff(props: SingleTariffProps) {
  const { operators } = useSelector((state) => state.calculation);
  const {
    minDays, maxDays, name, price, deliveryService, onClick, listPrice, localDelivery,
  } = props;


  function getDays(min: number, max: number) {
    if (min !== max) return 'дней в пути';
    return pluralize(min, 'день в пути,дня  в пути,дней  в пути')
  }

  // const operators = useSelector((state) => state.reference.operators);

  const operatorDisplay = React.useMemo(() => {
    if (operators && operators[deliveryService]) return operators[deliveryService].title;
    return '';
  }, [deliveryService, operators]);

  const operatorIcon = React.useMemo(() => {
    if (operators && operators[deliveryService]) return operators[deliveryService].icon;
    return null;
  }, [deliveryService, operators]);


  const totalPrice = React.useMemo(() => {
    let _ = price;
    // if (additionalServices?.length && activeAdditionalServices?.length) {
    //   additionalServices.filter((as) => activeAdditionalServices.includes(as.code)).forEach((as) => {
    //     if (as.code === RateAdditionalServiceCode.RETURN) _ += as.amount;
    //   });
    // }
    return _;
  }, [price]);

  return (
      <List.Item className={classNames('ant-list-item__hover rate-list__item', {'pls-pointer': !!onClick})} onClick={onClick}>
        <Row className="pls-align-items-center pls-w-100 pls-px-5 pls-ml-0">
          <Col xs={24} md={11} className="pls-pl-0">
            <div className="pls-font-weight-semi-bold pls-text-dark-blue">
              {name}
            </div>
            <div className="pls-text-muted">
              {(minDays === maxDays && minDays === 0) ? localDelivery ? 'В течении дня' : 'Сроки необходимо уточнить' : (
                  <>
                    {minDays !== maxDays ? `${minDays} - ${maxDays}` : minDays}
                    {' '}
                    {getDays(minDays, maxDays)}
                  </>
              )}
            </div>
          </Col>

          <Col xs={24} md={5} className="pls-d-flex pls-justify-content-center">
            <img style={{ maxHeight: 35 }} src={operatorIcon || ''} alt={operatorDisplay}/>
          </Col>

          {/* <Col xs={24} md={5}> */}
          {/*  <Rate */}
          {/*    allowHalf */}
          {/*    defaultValue={operatorRating} */}
          {/*    disabled */}
          {/*    className="pls-text-center pls-flex-row pls-align-items-center ant-row-flex-center pls-fs-sm" */}
          {/*  /> */}
          {/* </Col> */}

          <Col xs={24} md={{ span: 8 }} className="pls-text-right pls-pr-0 pls-text-secondary-2 pls-font-weight-bold">
            <div className="pls-d-flex pls-align-items-center pls-justify-content-end">
              {money(totalPrice)} руб
            </div>
            {listPrice && parseInt(price, 10) !== listPrice
            && (
                <div className="pls-text-muted pls-text-strikethrough pls-text-secondary-2 pls-font-weight-bold"
                     style={{ opacity: 0.6 }}>
                  {money(listPrice)} руб
                </div>
            )}
          </Col>
          {/*{additions && !!additions.length && (*/}
          {/*    <Col xs={24} className="pls-mt-2 pls-p-0 pls-text-muted">*/}
          {/*      {additions}*/}
          {/*    </Col>*/}
          {/*)}*/}
        </Row>
      </List.Item>
  );
}

export default SingleTariff;
