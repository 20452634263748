export const pluralize = (number, choices) => {
  const _choices = choices.split(',');
  if (_choices.length !== 3) return null;
  if (!number && number !== 0) return null;

  if (number <= 19 && number >= 11) return _choices[2];

  const lastDigit = parseInt(parseInt(number, 10).toString().split('').pop(), 10);
  if (lastDigit === 1) return _choices[0];
  if (lastDigit >= 2 && lastDigit <= 4) return _choices[1];
  if ((lastDigit >= 5 && lastDigit <= 9) || lastDigit === 0) return _choices[2];
  return '';
};
