type AdditionalServicesRenderType = {
  title: string,
  creationTitle: string,
  isWip?: boolean,
};

export const ADDITIONAL_SERVICES: {[key: string]: AdditionalServicesRenderType} = {
  CARGO_LOADING: {
    title: `Погрузка`,
    creationTitle: `Пoгpyзo-paзгpyзoчные работы при приеме груза`,
    isWip: true,
  },
  CARGO_UNLOADING: {
    title: `Разгрузка`,
    creationTitle: `Пoгpyзo-paзгpyзoчные работы при доставке груза`,
    isWip: true,
  },
  RETURN: {
    title: `Возврат`,
    creationTitle: `Возврат документов`,
  },
  BY_HAND: {
    title: `Вручение лично в руки`,
    creationTitle: `Вручение лично в руки`,
  },
};
