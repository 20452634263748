import React from 'react';

function Samsung() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" id="Livello_1" x="0" y="0" version="1.1" viewBox="0 0 4838 1606.3"
           xmlSpace="preserve">
        <path fill="#034ea2"
              d="M4835 382.5c65.9 377.7-962.3 872.3-2296.8 1104.7C1203.9 1719.6 68.8 1601.7 3 1223.8-62.8 846.1 965.7 351.6 2300 119.3 3634.4-113.3 4769.3 4.7 4835 382.5z"/>
        <path
            d="M3579.1 943.1l-6.8-392.7h123.8v495h-178l-123.6-406.2h-2.7l6.9 406.2h-123.1v-495h185.8l115 392.7zM1328.1 596.8l-68.5 453.8h-135.1l92.7-500.2h222.5l92.4 500.2h-134.6l-66.6-453.8zM1911.4 932.8l61.8-382.4h204l10.8 500.2h-125l-3.3-449.7h-2.6l-83.6 449.7h-126.9l-83.7-449.7h-2.7l-3.1 449.7h-125.3l11.1-500.2H1847l61.7 382.4zM913.9 909.5c4.9 12.1 3.4 27.7 1.1 37.1-4.2 16.6-15.5 33.6-48.7 33.6-31.4 0-50.4-18-50.4-45.4v-48.4H681.7l-.1 38.7c0 111.5 87.8 145.2 181.9 145.2 90.5 0 165-30.9 176.8-114.3 6.1-43.2 1.5-71.5-.5-82.2-21.1-104.7-211-136-225.1-194.5-2.4-10-1.7-20.7-.5-26.4 3.5-15.9 14.4-33.5 45.7-33.5 29.2 0 46.5 18.1 46.5 45.4v30.9h124.7v-35.1c0-109-97.8-126-168.6-126-89 0-161.7 29.4-175 110.8-3.6 22.5-4.1 42.5 1.1 67.6 21.9 102.1 199.5 131.7 225.3 196.5zM2540.4 908.6c4.9 12 3.3 27.2 1.1 36.6-4.1 16.6-15.3 33.3-48.3 33.3-30.9 0-49.8-18-49.8-44.8l-.1-48h-132.8l-.2 38.2c0 110.4 87 143.7 180.1 143.7 89.5 0 163.3-30.5 175-113.1 6.1-42.9 1.8-70.8-.4-81.3-21-103.7-208.9-134.7-222.9-192.7-2.4-10-1.7-20.5-.5-25.9 3.6-16.1 14.3-33.2 45.3-33.2 29 0 45.9 17.6 45.9 44.8v30.6h123.8V662c0-107.8-97-124.7-167.2-124.7-88 0-160.1 29-173.1 109.9-3.6 22.2-4 41.8 1.2 66.8 21.5 101.1 197.4 130.5 222.9 194.6zM2960.5 975.8c34.7 0 45.5-24 47.9-36.3 1-5.4 1.3-12.6 1.2-19.1V550.2h126.5V909c.3 9.2-.6 28.1-1.1 32.8-8.9 93.4-82.7 123.7-174.6 123.7-92 0-165.9-30.3-174.7-123.7-.4-4.7-1.3-23.7-1.1-32.8V550.2H2911v370.1c0 6.5.2 13.8 1.2 19.1 2.8 12.3 13.3 36.4 48.3 36.4zM4004 970.5c36.2 0 48.9-22.9 51.2-36.2.9-5.7 1.2-12.7 1.1-19v-72.6H4005v-73h177.4V904c-.1 9.4-.3 16.3-1.8 33-8.3 91-87.3 123.5-175.9 123.5-88.8 0-167.6-32.5-176.1-123.5-1.4-16.7-1.6-23.6-1.8-33l.1-210.6c0-8.9 1.1-24.6 2.1-33 11.1-93.5 86.9-123.6 175.7-123.6 88.7 0 166.4 29.9 175.6 123.6 1.6 15.9 1.1 33 1.1 33v16.7h-126.1v-28c.1.1-.2-11.9-1.6-19-2.1-11.1-11.7-36.5-49.9-36.5-36.5 0-47.1 24-49.8 36.5-1.5 6.6-2.1 15.5-2.1 23.6v228.8c-.1 6.3.3 13.3 1.3 19 2 13.1 14.6 36 50.8 36z"
            className="st1" fill="#fff"/>
</svg>
  );
}

export default Samsung;
