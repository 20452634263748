import React, { ChangeEvent, ChangeEventHandler, SyntheticEvent } from 'react';
import { Col, Input, Row } from 'antd';
import useDispatch from '../utils/hooks/useDispatch';
import { updateCargo } from '../redux/calculation/action-creators';
import useSelector from '../utils/hooks/useSelector';
import classNames from 'classnames';
import { ErrorContext } from './ErrorProvider';

const floatRe = /^\d+([.,]\d*)?$/g;
const intRe = /\d+/g;


function CargoForm() {
  const dispatch = useDispatch();
  const { cargo } = useSelector((state) => state.calculation);
  const errors = React.useContext(ErrorContext);

  const error = React.useMemo(() => {
    return errors.error['cargo'];
  }, [errors]);


  const handleChange = React.useCallback(
      (e: ChangeEvent<HTMLInputElement>, field: string) => {
        const { value } = e.target;
        let _value: string | number | undefined = value;
        if (field === 'weight') _value = value.match(floatRe)?.join('');
        else _value = value.match(intRe)?.join('');
        if (_value || value === '') {
          const newData = { [field]: _value };
          dispatch(updateCargo(newData));
          if (error) errors.setErrors({ cargo: undefined });
        } else {
          e.preventDefault();
          e.stopPropagation();
        }
        // if (errors && Object.prototype.hasOwnProperty.call(errors, field)) {
        //   const newErrors = { ...errors, [field]: undefined };
        //   setErrors(newErrors);
        // }
      },
      [error],
  );

  return (
      <div className={classNames('pls-calculation-form-cargo pls-z-index-10 pls-relative', { 'has-error': !!error })}>
        <Row>
          {/*<Col xs={15} className="pls-calculation-form-cargo-dimensions">*/}
          {/*  <Row>*/}
          {/*    <div className="ant-col ant-col-xs-24">*/}
          {/*      <label*/}
          {/*          className=""*/}
          {/*          title={'Габариты'}*/}
          {/*      >*/}
          {/*        Габариты*/}
          {/*      </label>*/}
          {/*    </div>*/}
          {/*    <Col span={24}>*/}
          {/*      <Input.Group size="large">*/}
          {/*        <Input value={cargo.length ?? ''} style={{ width: '33.33%' }} placeholder="Д"*/}
          {/*               onChange={(e) => handleChange(e, 'length')}/>*/}
          {/*        <span>x</span>*/}
          {/*        <Input value={cargo.width ?? ''} style={{ width: '33.33%' }} placeholder="Ш"*/}
          {/*               onChange={(e) => handleChange(e, 'width')}/>*/}
          {/*        <span>x</span>*/}
          {/*        <Input value={cargo.height ?? ''} style={{ width: '33.33%' }} placeholder="В"*/}
          {/*               onChange={(e) => handleChange(e, 'height')}/>*/}

          {/*      </Input.Group>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</Col>*/}
          <Col xs={24} className="pls-calculation-form-cargo-weight">
            <Row>
              <Col xs={24}>
                <label
                    className=""
                    title={'Вес'}
                >
                  Вес
                </label>
              </Col>
              <Col span={24}>
                <Input value={cargo.weight ?? ''} placeholder="кг" onChange={(e) => handleChange(e, 'weight')}/>
              </Col>
            </Row>
          </Col>
        </Row>
        {!!error && <div className="pls-fs-sm pls-text-red">{error}</div>}
      </div>
  );
}

export default CargoForm;
