import React from 'react';
import { Col, Row } from 'antd';

function AdditionalServices() {
  return (
      <div className="pls-additional-services">
        <Row>
          <Col xs={24} lg={10} className="pls-pr-5">
            <h2>
              Дополнительные услуги
            </h2>
            <div>
              Компания BelkaPost предоставляет полный<br/>
              комплекс услуг. Подключив их — агрегатор <br/>
              превращается в удобную CRM с трекингом <br/>
              отправлений, актуальными статусами заказов.
            </div>
          </Col>
          <Col xs={24} lg={14} className="pls-mt-4 pls-mt-lg-0">
            <div className="pls-additional-services-features">
              <div className="pls-additional-services-features-container">
                <div className="pls-additional-services-features-content">
                  Персональный менеджер от <br/> каждой курьерской службы
                </div>
              </div>
              <div className="pls-additional-services-features-container">
                <div className="pls-additional-services-features-content pls-additional-services-features-content-alternative">
                  Экспресс реакция <br/> на заявки
                </div>
              </div>
              <div className="pls-additional-services-features-container">
                <div className="pls-additional-services-features-content pls-additional-services-features-content-alternative">
                  Страхование груза
                </div>
              </div>
              <div className="pls-additional-services-features-container">
                <div className="pls-additional-services-features-content">
                  Кассовое обслуживание <br/> и другие услуги
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
  );
}

export default AdditionalServices;
