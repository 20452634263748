import React from 'react';
import { Col, Row } from 'antd';
import SingleTariff, { SingleTariffProps } from '../../../components/SingleTariff';
import Icon from '@ant-design/icons';
import { Step1, Step2, Step3, Step4, Step5, Step6 } from '../../../components/icons/Steps';

const tariffList: SingleTariffProps[] = [
  {
    rateResultId: 'dummy-1',
    deliveryService: 'cse',
    code: 'dummy-1',
    name: 'Доставка по Москве Суточная',
    price: '280',
    minDays: 1,
    maxDays: 1,
    listPrice: 400,
    localDelivery: false,
  },
  {
    rateResultId: 'dummy-2',
    deliveryService: 'cdek',
    code: 'dummy-2',
    name: 'Экспресс лайт дверь-дверь  ',
    price: '285',
    minDays: 1,
    maxDays: 1,
    listPrice: 380,
    localDelivery: false,
  },
  {
    rateResultId: 'dummy-3',
    deliveryService: 'cse',
    code: 'dummy-3',
    name: 'Доставка по Москве Срочная',
    price: '341',
    minDays: 1,
    maxDays: 1,
    listPrice: 487,
    localDelivery: false,
  },
  {
    rateResultId: 'dummy-4',
    deliveryService: 'cse',
    code: 'dummy-4',
    name: 'Доставка по Москве Сверхсрочная',
    price: '544',
    minDays: 1,
    maxDays: 1,
    listPrice: 777,
    localDelivery: false,
  },
  {
    rateResultId: 'dummy-5',
    deliveryService: 'cdek',
    code: 'dummy-5',
    name: 'Супер экспресс до 16',
    price: '285',
    minDays: 1,
    maxDays: 1,
    listPrice: 380,
    localDelivery: false,
  },
  {
    rateResultId: 'dummy-6',
    deliveryService: 'cdek',
    code: 'dummy-6',
    name: 'Супер экспресс до 18',
    price: '285',
    minDays: 1,
    maxDays: 1,
    listPrice: 380,
    localDelivery: false,
  }

]

function TariffBlock() {
  return (
      <div className="pls-tariff-block" id="tariffs">
        <Row>
          <Col xs={24} xl={12} className="pls-tariff-block-preview">
            <div className="pls-tariff-block-preview-content">
              <div className="pls-tariff-block-preview-title pls-fs-2xl">
                Тарифы
              </div>
              <div className="pls-tariff-block-preview-list">
                {tariffList.map((_) => <SingleTariff {..._} />)}
              </div>
            </div>
          </Col>
          <Col xs={24} xl={12} className="pls-pl-xl-9 pls-mt-3 pls-mt-xl-0">
            <h2 className="pls-text-dark pls-font-weight-semi-bold">
              Как мы сделали доставку дешевле?
            </h2>
            <ul className="pls-text-dark-blue">
              <li>
                Мы даем службам доставки много отгрузок, а они нам – оптовые цены.
              </li>
              <li>
                Система ищет самую дешевую доставку по заданному адресу.
              </li>
              <li>
                Службы доставки конкурируют и дополнительно снижают цены для наших клиентов.
              </li>
              <li>
                Персональный менеджер и быстрая реакция на запросы клиентов – условие подключения службы доставки к
                агрегатору.
              </li>
            </ul>
          </Col>
        </Row>
      </div>
  );
}

export default TariffBlock;
