import React from 'react';
import { Button, Form, Input, Modal, notification } from 'antd';
import InputMask from '../../../components/InputMask';
import { useForm } from 'antd/es/form/Form';
import qs from 'querystring';
import axios from 'axios';
import useDispatch from '../../../utils/hooks/useDispatch';
import { createCallback } from '../../../redux/callback/action-creators';
import { FieldData } from 'rc-field-form/es/interface';

function Callback() {

  const handleCallback = React.useCallback(() => {
    // @ts-ignore
    const buttons: any[] = window.BX?.SiteButton?.buttons?.list || [];

    const crmform = buttons.find((_) => _.id === 'crmform');
    if (crmform) {
      crmform.onclick();
      return;
    }
    const callback = buttons.find((_) => _.id === 'callback');
    if (callback) {
      callback.onclick();
      return ''
    }

    // @ts-ignore
    window.BX?.LiveChat?.openLiveChat()

  }, []);


  return (
      <div className="pls-callback pls-text-center">

        <h3 className="pls-mb-6">
          Остались вопросы или хотите
          <br/>
          заказать звонок?
        </h3>
        <Button onClick={handleCallback} className="pls-button pls-button-rounded pls-button-red">
          Связаться с нами
        </Button>
      </div>
  );
}

export default Callback;
