import React from 'react';

type ErrorContextType = {
  error: anyObject,
  setErrors: (errors: anyObject) => void,
}

export const ErrorContext = React.createContext({} as unknown as ErrorContextType);

type ErrorProviderProps = {
  children: any,
}

function ErrorProvider({ children }: ErrorProviderProps) {
  const [state, dispatch] = React.useReducer((state: anyObject, actions: any) => {
    return {...state, ...actions};
  }, {});

  return (
      <ErrorContext.Provider value={{ error: state, setErrors: dispatch }}>
        {children}
      </ErrorContext.Provider>
  );
}

export default ErrorProvider;
