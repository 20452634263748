import React from 'react';
import useDispatch from './utils/hooks/useDispatch';
import { loadOperators } from './redux/calculation/action-creators';
import CircularProgress from './components/CircularProgress';
import Router from './Router';
import subscribe from './utils/websocket';

function MainWrapper() {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    dispatch(subscribe());
    setLoading(true);
    dispatch(loadOperators()).finally(() => setLoading(false));
  }, []);


  if (loading) return <CircularProgress />

  return <Router/>;
}

export default MainWrapper;
