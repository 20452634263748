import Centrifuge from 'centrifuge';
import { adaptFromApi } from './adapter';
import { ThunkAction } from '../types';
import { acceptWsCalculation, setCent } from '../redux/calculation/action-creators';
import getAppConfig from './getAppConfig';

const ACCESS_TOKEN = getAppConfig().token;

export default function subscribe(): ThunkAction<void> {
  return (dispatch, getState, http) => {
    const { cent } = getState().calculation;
    if (cent) cent.disconnect();
    console.debug('connect')
    const centrifugeHost = getAppConfig().centrifugeHost;
    const c = new Centrifuge(`wss://${centrifugeHost}/connection/websocket`, { debug: true });
    c.setToken(ACCESS_TOKEN);
    c.subscribe('calculation#-1', (message) => {
      const {
        data: {
          singleTaskId, command, data,
        },
      } = adaptFromApi(message);
      if (singleTaskId) {
        dispatch(acceptWsCalculation(singleTaskId, command, data));
      }
    });
    c.connect();
    dispatch(setCent(c));
  };
}
