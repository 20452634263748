import { ThunkDispatch as ReduxThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '../reducers';
import { HttpService } from './httpService/createHttpService';

export type ThunkDispatch = ReduxThunkDispatch<RootState, any, Action>;

export type ThunkAction<R = Promise<any>> = (
  dispatch: ThunkDispatch,
  getState: () => RootState,
  http: HttpService
) => R;

export const DESTROY_SESSION = 'DESTROY_SESSION';
