import { createBrowserHistory } from 'history';
import { Action, combineReducers } from 'redux';
import { DESTROY_SESSION } from './types';
import calculation from './redux/calculation/reducer';
import settings from './redux/settings/reducer';

const history = createBrowserHistory();

const appReducer = combineReducers({
  calculation,
  settings,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState | undefined, action: Action): RootState => {
  if (action.type === DESTROY_SESSION) {
    // @ts-ignore
    return appReducer({ reference: state?.reference }, action);
  }
  // @ts-ignore
  return appReducer(state, action);
};

export default rootReducer;
export { history };
