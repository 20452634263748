import React from 'react';
import { Col, Row } from 'antd';

function Features() {
  return (
      <div className="pls-features">

        <Row>
          <Col xs={24} md={8} className="pls-features-feature pls-pr-5">
              Снижение расходов на логистику в 1-2 раза
          </Col>
          <Col xs={24} md={8} className="pls-features-feature pls-pr-5">
              Обнаружение и решение проблем с помощью искуственного интелекта
          </Col>
          <Col xs={24} md={8} className="pls-features-feature pls-pr-5">
              Мы не скрываем проблемы, мы решаем их
          </Col>
          <Col xs={24} md={8} className="pls-features-feature pls-pr-5">
              Бонусная система и система CASHBACK
          </Col>
          <Col xs={24} md={8} className="pls-features-feature pls-pr-5">
              Храним всю историю заказов
          </Col>
          <Col xs={24} md={8} className="pls-features-feature pls-pr-5">
              Полная прозрачность выполнения заказов
          </Col>
        </Row>

      </div>
  );
}

export default Features;
