import {
  ADD_CALCULATION_RESULTS, AdditionalServices, AdditionalServiceStatus,
  CalculationActions, CalculationFilter, CurrentCalculationFilter,
  GeoSuggest,
  Operator,
  Package,
  RESET_CALCULATION, SET_ADDITIONAL_SERVICES,
  SET_CALCULATION_DONE,
  SET_CALCULATION_RESULTS,
  SET_CALCULATION_TASK_ID,
  SET_CENT,
  SET_OPERATORS,
  SET_ORDERING,
  SET_RECEIVER_ADDRESS,
  SET_SENDER_ADDRESS,
  SET_WEIGHT,
  SingleRate, SWITCH_CURRENT_FILTER,
  UPDATE_CARGO, UPDATE_CURRENT_DELIVERY_SERVICE_FILTER, UPDATE_SERVICES_FILTER
} from './types';
import Centrifuge from 'centrifuge';

type CalculationState = {
  operatorList: Operator[],
  operators: { [key: string]: Operator },
  cent: Centrifuge | null,
  senderAddress: GeoSuggest | null,
  receiverAddress: GeoSuggest | null,
  cargoId: string | null,
  weight: string,
  taskId: string | null,
  results: SingleRate[],
  cargo: Package,
  currentOrdering: 'price' | 'minDays',
  calculationDone: boolean,
  currentFilters: CurrentCalculationFilter,
  availableFilters: CalculationFilter,
  additionalServices: AdditionalServices,
}

const initialState: CalculationState = {
  operatorList: [],
  operators: {},
  cent: null,
  senderAddress: null,
  receiverAddress: null,
  cargoId: null,
  weight: '0.2',
  taskId: null,
  results: [],
  cargo: {},
  currentOrdering: 'price',
  calculationDone: false,
  currentFilters: {
    deliveryServices: {
      touched: false,
      values: [],
      open: true,
    },
    services: {
      touched: false,
      values: [],
      open: true,
    },
  },
  availableFilters: {
    deliveryServices: [],
    services: [],
  },
  additionalServices: []
}

function switchFilter(currentFilters: CurrentCalculationFilter, filter: 'deliveryServices' | 'services'): CurrentCalculationFilter {
  const tmp = { ...currentFilters };
  tmp[filter].open = !tmp[filter].open;

  return tmp;
}

function calcFilter(currentRates: Array<SingleRate>): CalculationFilter {
  const deliveryServices: Array<string> = [];
  const services: string[] = [];
  currentRates.forEach((rate) => {
    if (!deliveryServices.includes(rate.deliveryService)) deliveryServices.push(rate.deliveryService);
    rate.additionalServices.forEach((service) => {
      if (service.status !== AdditionalServiceStatus.NOT_AVAILABLE && !services.includes(service.code)) {
        services.push(service.code);
      }
    });
  });
  return {
    deliveryServices,
    services,
  };
}

function checkCurrentFilter(currentCalculationFilter: CurrentCalculationFilter, availableFilters: CalculationFilter) {
  const tmp = { ...currentCalculationFilter };
  // Службы
  if (!tmp.deliveryServices.touched) tmp.deliveryServices.values = availableFilters.deliveryServices;
  return tmp;
}

const handleResult = (state: CalculationState, newResults: SingleRate[]) => {
  const newState = { ...state };
  newState.results = [...newState.results, ...newResults];
  newState.availableFilters = calcFilter(newState.results);
  newState.currentFilters =  checkCurrentFilter(newState.currentFilters, newState.availableFilters);
  return newState;
}

export default function (state = initialState, action: CalculationActions): CalculationState {
  switch (action.type) {
    case SET_OPERATORS:
      return {
        ...state, operators: action.operators.reduce((cur, operator) => {
          cur[operator.key] = operator;
          return cur;
        }, {} as { [key: string]: Operator }), operatorList: action.operators
      }
    case SET_CENT:
      return { ...state, cent: action.cent };
    case SET_SENDER_ADDRESS:
      return { ...state, senderAddress: action.address };
    case SET_RECEIVER_ADDRESS:
      return { ...state, receiverAddress: action.address };
    case RESET_CALCULATION:
      return { ...state, taskId: initialState.taskId, results: initialState.results };
    case SET_CALCULATION_TASK_ID:
      return { ...state, taskId: action.taskId };
    case SET_CALCULATION_RESULTS:
      return handleResult({ ...state, results: action.results }, []);
    case ADD_CALCULATION_RESULTS:
      return handleResult(state, action.results);
    case SET_WEIGHT:
      return { ...state, weight: action.weight };
    case UPDATE_CARGO:
      return { ...state, cargo: { ...state.cargo, ...action.cargo } }
    case SET_ORDERING:
      return { ...state, currentOrdering: action.ordering };
    case SET_CALCULATION_DONE:
      return { ...state, calculationDone: action.done };
    case UPDATE_CURRENT_DELIVERY_SERVICE_FILTER:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          deliveryServices: {
            ...state.currentFilters.deliveryServices,
            touched: true,
            values: action.deliveryServices,
          },
        },
      };
    case UPDATE_SERVICES_FILTER:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          services: {
            ...state.currentFilters.services,
            touched: !action.initial,
            values: action.services,
          },
        },
      };
    case SET_ADDITIONAL_SERVICES:
      return { ...state, additionalServices: action.services };
    case SWITCH_CURRENT_FILTER:
      return { ...state, currentFilters: switchFilter(state.currentFilters, action.filter) };

    default:
      return state;
  }
}
