import React from 'react';
import beeline from '../../../assets/images/BeeLine_logo.png';
import Icon from '@ant-design/icons';
import Samsung from '../../../components/icons/Samsung';
import Rosneft from '../../../components/icons/Rosneft';
import Gazprom from '../../../components/icons/Gazprom';
import BeeLine from '../../../components/icons/BeeLine';

function Clients() {
  return (
      <div className="pls-clients pls-text-center">
        <h2>
          Наши клиенты
        </h2>
        <div className="pls-text-dark-blue pls-opacity-80">
          Не имеет значения сколько у вас отгрузок
          <br/>
          в день – 1 или 999999. Все наши клиенты
          <br/>
          получают одинаковые лучшие условия.
        </div>

        <div className="pls-clients-list-wrapper pls-mt-100">
          <div className="pls-clients-list">
            <Icon className="pls-w-100 pls-flex-1" component={Samsung}/>
            <Icon className="pls-w-100 pls-flex-1" component={Rosneft}/>
            <Icon className="pls-w-100 pls-flex-1" component={BeeLine}/>
            {/*<img src={beeline} alt=""/>*/}
            {/*<div*/}
            {/*    className="pls-w-100 pls-flex-1"*/}
            {/*    style={{*/}
            {/*      backgroundImage: `url(${beeline})`,*/}
            {/*      backgroundPosition: 'center',*/}
            {/*      backgroundRepeat: 'no-repeat',*/}
            {/*      backgroundSize: 'contain',*/}
            {/*      width: '100%',*/}
            {/*      height: '100%',*/}
            {/*    }}*/}
            {/*/>*/}
            <Icon className="pls-w-100 pls-flex-1" component={Gazprom}/>
          </div>
        </div>
      </div>
  );
}

export default Clients;
