import React from 'react';
import AddressForm from '../../../components/AddressForm';
import CargoForm from '../../../components/CargoForm';
import { Button } from 'antd';
import useDispatch from '../../../utils/hooks/useDispatch';
import { startCalculation } from '../../../redux/calculation/action-creators';
import { ErrorContext } from '../../../components/ErrorProvider';
import { scrollToElement } from '../../../utils/scroll';


function CalculationForm() {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const errorContext = React.useContext(ErrorContext);

  const handleCalc = React.useCallback(
      () => {
        setLoading(true);
        dispatch(startCalculation()).then(
            () => {
              const calcDiv = document.getElementById('calculation');
              if (calcDiv) scrollToElement(calcDiv);
            },
            (errors) => errorContext.setErrors(errors),
        ).finally(() => setLoading(false));
      },
      [],
  );


  return (
      <div className="pls-calculation">
        <div className="pls-calculation-form">
          <div>
            <AddressForm side="sender"/>
          </div>
          <div>
            <AddressForm side="receiver"/>
          </div>
          <div>
            <CargoForm/>
          </div>
          <div style={{ paddingTop: 32 }}>
            <Button onClick={handleCalc} style={{ height: 58 }} loading={loading} type="primary"
                    className="pls-button pls-w-100 pls-z-index-10 pls-relative">
              Найти
            </Button>
          </div>
        </div>
      </div>
  );
}

export default CalculationForm;
