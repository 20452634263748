import React from 'react';

function Down() {
  return (
      <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M38.0048 51.4394C38.4929 51.9276 39.2844 51.9276 39.7726 51.4394L47.7275 43.4845C48.2157 42.9963 48.2157 42.2049 47.7275 41.7167C47.2393 41.2286 46.4479 41.2286 45.9597 41.7167L38.8887 48.7878L31.8176 41.7167C31.3294 41.2286 30.538 41.2286 30.0498 41.7167C29.5617 42.2049 29.5617 42.9963 30.0498 43.4845L38.0048 51.4394ZM37.6387 27.2222L37.6387 50.5556L40.1387 50.5556L40.1387 27.2222L37.6387 27.2222Z"
            fill="white" fillOpacity="0.5"/>
        <circle cx="38.5" cy="38.5" r="37.25" stroke="white" strokeOpacity="0.5" strokeWidth="2.5"/>
      </svg>

  );
}

export default Down;
